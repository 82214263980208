<template>
  <div class="page">

    <HeadBar class="mine-headbar" title="会员中心" />

    <div class="mine">
      <MineVip v-if="browserType !== 'landscape'" class="mine-vip-v" from="profile" />
      <MineVipc from="profile" v-else />
    </div>

    <LbBanner />
    
  </div>
</template>

<script>
import { computed, onMounted, ref, watch } from 'vue'
import { useStore } from 'vuex'
// import { useRouter } from 'vue-router'
import { sendLog } from '@/directives/v-log/log'
import MineVip from '@/components/vip/index.vue'
import MineVipc from '@/pages/mine/components/vipc.vue'
import LbBanner from '@/components/lb-banner/index.vue'

// import { getSingsingList } from '@/service/singing'
import { useShareBrowserSize } from '@/composables/sharedComposable'

// import SongItem from '@/components/song-item/index.vue'
// import GuessSonglist from '@/components/guess-song/songlist.vue'

export default {
  name: 'Mine',
  components: {
    MineVip,
    MineVipc,
    LbBanner,
    // SongItem,
    // GuessSonglist,
  },
  setup() {
    const store = useStore()
    const { browserType } = useShareBrowserSize()

    // const router = useRouter()
    // const unionid = computed(() => store.state.userInfo.unionid)

    // const dataList = ref([])
    // let isRequest = false

    // const requestSingingData = async () => {
    //   let responseData = []
    //   responseData = await getSingsingList({
    //     unionid: unionid.value,
    //   })
    //   return responseData
    // }

    // const fetchData = async () => {
    //   if (!unionid.value) return
    //   if (isRequest) {
    //     return
    //   }
    //   isRequest = true
    //   const singingResponseData = await requestSingingData()
    //   dataList.value = singingResponseData
    //   isRequest = false
    // }

    // watch(unionid, (val) => {
    //   if (val) {
    //     fetchData()
    //   }
    // })

    onMounted(() => {
      // sendLog({
      //   event_type: '10000~50000',
      //   event_name: 10026,
      //   event_data: {
      //     str1: '我的',
      //     str2: '我的页',
      //     str3: '进入我的页',
      //     str4: 'show',
      //   },
      // })
      // fetchData()
    })

    return {
      // dataList,
      browserType,
    }
  },
}
</script>

<style lang="stylus" scoped>
.mine
  width 100%
  padding 0 100px
  height calc(100vh - 164px)
  overflow-y scroll
  ::-webkit-scrollbar
    display none
  &-singed
    margin-top 36px
    padding-bottom 250px
    &-title
      color: rgba(255, 255, 255, 0.60);
      padding 36px 0 25px
      border-bottom 2px solid rgba(255, 255, 255, 0.1)
      span
        font-size 32px
        @media screen and (max-width 1200px)
          font-size 26px
    .song-list
      padding 40px 0 0
      height auto!important
      &::-webkit-scrollbar
        display: none
      @media screen and (max-width 1200px)
        min-height 304px
        overflow hidden
        padding-top 32px
        ::v-deep .song-block, ::v-deep .song-item
          .name
            font-size 26px
          .desc
            font-size 20px
          .song-block-vip
            width 42px
            height 22px
    &-empty
      padding-bottom 50px
      h3
        width 480px
        text-align center
        color rgba(255, 255, 255, 0.4)
        font-size: 28px;
        padding-top 130px
        background url(https://qncweb.ktvsky.com/20231208/other/8735391456ed4d23857d801ce6c5482b.svg) no-repeat top center
        background-size 90px auto
        margin 96px auto 80px
      ::v-deep .sec-gusse-sing
        padding 0!important
        &-list
          display grid
          grid-template-columns repeat(3, 493px)
          justify-content space-between
          @media screen and (max-width 1200px)
            grid-template-columns repeat(3, 344px)
            min-height 304px
            overflow hidden
            ::v-deep .song-item
              width 344px
              height 140px
              margin-bottom 24px
              .name
                font-size 26px
              .desc
                font-size 20px
              .song-block-vip
                width 42px
                height 22px
          .song-block
            width 100%
  @media screen and (max-width 1200px)
    padding 0
.page
  height 100vh
  overflow hidden
  background: #000000B2
  .search-bar
    background: none
  .mine-headbar
    background: none
  .mine-vip-v
    @media screen and (max-width 1200px)
      position absolute
      top 785px
      left 60px
      width 1080px
      height 514px
      z-index 1
      zoom 1
      ::v-deep
        .user-vip-openvip
          zoom 0.7
        .user-vip-entry
          div
            &:nth-child(1)
              margin-right 0
              // display none
            &:nth-child(2)
              // display flex
</style>

